.header {
  background-color: #ededee;
  text-align: center;
  color: black;
  max-width: fit-content;
}

.headerText {
  font-family: "WF-Segoe-UI-Semilight", "Segoe UI Semilight", "Segoe WP Semilight", "Segoe UI", Tahoma, Arial, Sans-Serif;
  font-weight: bold;
  font-size: 1.4em;
  opacity: 0.9;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .headerText {
    font-size: 0.8em;
  }
}

.headerTitleContainer {
  text-align: left;
}

.titleText {
  font-family: "WF-Segoe-UI-Semilight", "Segoe UI Semilight", "Segoe WP Semilight", "Segoe UI", Tahoma, Arial, Sans-Serif;
  font-size: 1.1em;
  font-weight: normal;
  opacity: 0.9;
  padding-top: 5px;
}

@media screen and (max-width: 600px) {
  .titleText {
    font-size: 0.8em;
  }
}

.userIcon {
  font-size: 2.3em !important;
  padding-top: 10px;
}

@media screen and (max-width: 600px) {
  .userIcon {
    font-size: 1.9em !important;
  }
}
