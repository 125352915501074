.section {
  box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}

.dateSelected {
  font-family: "WF-Segoe-UI-Semilight", "Segoe UI Semilight", "Segoe WP Semilight", "Segoe UI", Tahoma, Arial, Sans-Serif;
  font-weight: 600;
  font-size: 15px;
  opacity: 0.9;
}
