.section {
  padding: 10px;
  /* box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 10%); */
  background-color: #ffffff;
}

.appointment {
  background-color: #002b51;
  color: #ffffff;
  text-align: left;
  line-height: 20px;
  display: inline-block;
  position: relative;
  padding: 5px 20px;
  border-radius: 12px;
}

.name {
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin-top: 2px;
}

.details {
  display: inline-block;
  font-size: 12px;
  line-height: 21px;
  font-weight: bold;
}

.gridRow {
  padding-bottom: 8px;
}

.button {
  background-color: #ffffff;
  color: #000;
}

.actionButton {
  background-color: #2699fb;
  color: #ffffff;
  font-size: 10px;
  margin: 5px;
}

.actionButton:hover {
  background-color: #ededee;
  color: black;
  font-weight: bold;
}

.icon {
  font-size: 20px;
}
