body {
  margin: 0;
  font-family: "WF-Segoe-UI-Semilight", "Segoe UI Semilight", "Segoe WP Semilight", "Segoe UI", Tahoma, Arial, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
