.section {
  padding: 10px;
  box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}

.appointment {
  background-color: #002b51;
  color: #ffffff;
  width: 46%;
  text-align: left;
  line-height: 20px;
  display: inline-block;
  position: relative;
  padding: 5px 20px;
}

.name {
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
}

.details {
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
}

.gridRow {
  padding-bottom: 8px;
}
