.footer {
  background-color: #001f53;
  position: fixed;
  bottom: 0;
  color: #fff;
  left: 0;
  width: 100%;
  height: 36px;
  z-index: 1200;
  padding-bottom: 3px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .footer {
    background-color: #001f53;
    position: fixed;
    bottom: 0;
    color: #fff;
    left: 0;
    width: 100%;
    height: 36px;
    z-index: 1200;
    padding-bottom: 5px;
    font-weight: bold;
  }
}
